import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { ChatgptContext } from "../../../contexts/chatgptContext";
import MyMsg from "./MyMsg";
import GptMsg from "./GptMsg";

export default function Chat() {
  const { data } = useContext(ChatgptContext);
  const chatRef = useRef();

  useEffect(() => {
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [data]);

  const username = localStorage.getItem("openaiAPI");
  const welcomeMessage = {
    message: `Χαίρετε ${username}! Είμαι ο στρατιωτικός βοηθός τεχνητής νοημοσύνης του ΥΠΕΘΑ και δέχομαι ερωτήσεις σχετικά με την εισαγωγή υποψηφίων στις Ένοπλες Δυνάμεις. Πως μπορώ να σας βοηθήσω;`,
    urls: ""
  };

  return (
    <ChatStyled ref={chatRef}>

      {/* Render the custom initial text */}
      <div className="result" key={-1}>
        <GptMsg text={welcomeMessage} />
      </div>

      {/* Render the rest of the messages */}
      {data.map((d, i) => (
        <div className="result" key={i}>
          <MyMsg text={d[0]} />
          <GptMsg text={d[1]} />
        </div>
      ))}
      
    </ChatStyled>
  );

  // return (
  //   <ChatStyled ref={chatRef}>
  //     {/* Render the custom initial text */}
  //     <div className="result" key={-1}>
  //       <GptMsg text={`Hello! How can I assist you today ${username}?`} />
  //     </div>
  //     {/* Render the streamed messages */}
  //     {data.map((message, index) => (
  //       <div className="result" key={index}>
  //         {message.map((text, i) => (
  //           <React.Fragment key={i}>
  //             {i % 2 === 0 ? (
  //               <MyMsg text={text} />
  //             ) : (
  //               <GptMsg text={text} />
  //             )}
  //           </React.Fragment>
  //         ))}
  //       </div>
  //     ))}
  //   </ChatStyled>
  // );
}

const ChatStyled = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  .result {
    display: flex;
    flex-direction: column;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;