import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import logo from '../../assets/logo.png';
import buttonLogo from '../../assets/enter-arrow.png'; // Import the button logo

export default function Login({ setShowapp }) {
  const [api, setApi] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    setApi(localStorage.getItem("openaiAPI"));
    inputRef.current.focus();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    localStorage.setItem("openaiAPI", api);
    setShowapp(true);
  }

  return (
    <LoginStyled onSubmit={handleSubmit}>
      <img src={logo} className="application-logo" alt="logo" />
      <h1>Υπουργείο Εθνικής Άμυνας</h1>
      
      <input
          ref={inputRef}
          onChange={(e) => setApi(e.target.value)}
          type="text"
          value={api}
          placeholder="Ορίστε μία προσφώνηση για εσάς..."
        />
      <button type="button" onClick={handleSubmit}>
         Enter
        </button>
      
      <p>Καλωσήλθατε στον Ψηφιακό Βοηθό του ΥΠΕΘΑ.<br />
      Η εφαρμογή, στην παρούσα φάση, βρίσκεται σε Δοκιμαστική Έκδοση - Beta Version.<br />
      Παρακαλούμε αρχικά εισάγετε μιά προσφώνηση για εσάς.<br />
      Στη συνέχεια μπορείτε να πραγματοποιήσετε ερωτήσεις στην <u><strong>ελληνική</strong></u> γλώσσα.
      </p>


    </LoginStyled>
  );
}

/*-------------------------------*/
const LoginStyled = styled.form`
  width: 100%;
  height: 100vh;
  background-color: #2c3e50;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin-bottom: 20px;
    font-size: 50px;
    text-align: center;
    margin: 5px;
    margin-bottom: 1em;

    @media (max-width: 600px) {
      font-size: 28px;
    }
  }

  p {
    margin-top: 2em;
    text-align: center;
  }

  input {
    height: 50px;
    width: 400px;
    text-align: center;
    font-size: 16px;
    outline: none;
    // border: none;
    border-radius: 50px;
    border-color: black;

    @media (max-width: 600px) {
      width: 60%;
    }
  }
  
  button {
    height: 50px;
    width: 100px;
    text-align: center;
    font-size: 16px;
    outline: none;
    // border: 4px;
    border-radius: 50px;
    border-color: black;
    background-color: #c8cbe3;
    margin-top: 1em;
    transition: width 0.3s ease; /* Smooth transition for the width */
  }
  button:hover {
  width: 200px; /* Increase width on hover */
  }

  .application-logo {
    max-width: 100%;
    height: auto;
    width: 175px;
    height: 200px;
    margin-bottom: 1em;
    
    @media (max-width: 600px) {
      width: 100px;
      height: auto;
    }
}
`;
