import React, { useState } from "react";
import Timer from "./Timer";
import styled from "styled-components";
import logo from '../../assets/logo.png';

export default function Header({ theme, toggleTheme, expiryTimestamp }) {
  // const [theme, setTheme] = useState("dark");

  // const toggleTheme = () => {
  //   if (theme === "dark") {
  //     setTheme("light");
  //     document.body.style.background = "#e2e2e2";
  //     document.body.style.color = "black";
  //   } else {
  //     setTheme("dark");
  //     document.body.style.background = "#2c3e50";
  //     document.body.style.color = "white";
  //   }
  // };

  return (
    <HeaderStyled>
      <a href="https://www.mod.mil.gr/" target="_blank" rel="noopener noreferrer">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={logo} alt="Logo" style={{ marginRight: "15px", width: "69px", height: "79px" }} />
          <div>
            <h1 style={{ marginRight: "10px", fontSize: "20px" }}>
              Υπουργείο Εθνικής Άμυνας
            </h1>
            <h3 >
              MilitaryAssistantBot - BetaVersion
            </h3>
          </div>
        </div>
      </a>

      <div>
        {/* Show the Timer component only if expiryTimestamp exists */}
        {expiryTimestamp && <Timer expiryTimestamp={expiryTimestamp} />}        
      </div>

      <a href="/communication.html">
            <strong>Επικοινωνία</strong>
      </a>  

      <div onClick={toggleTheme} className="theme">
        {theme === "dark" ? "Light" : "Dark"}
      </div>
    </HeaderStyled>
  );
}

const HeaderStyled = styled.header`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    text-decoration: none;
    color: white;
    transition: 0.5s ease-out;
  }
  .theme {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    user-select: none;
    margin-right: 10px; /* Adjust this value as needed */
  }
`;
