import React, { useState } from "react";
import styled from "styled-components";

export default function Footer({ theme }) {
  // const [theme, setTheme] = useState("dark");

  // const toggleTheme = () => {
  //   if (theme === "dark") {
  //     setTheme("light");
  //     document.body.style.background = "#e2e2e2";
  //     document.body.style.color = "black";
  //   } else {
  //     setTheme("dark");
  //     document.body.style.background = "#2c3e50";
  //     document.body.style.color = "white";
  //   }
  // };

  return (
    <FooterStyled>
      <div>
        <p>
        Η τεχνητή νοημοσύνη <u><strong>μπορεί να κάνει λάθη</strong></u>. Οφείλετε να διασταυρώσετε τα αποτελέσματα απο έγκυρες πηγές. Ελέγξτε την {" "}
          <a href="/terms-of-use.html">
            Πολιτική Χρήσης
          </a>
        </p>
      </div>

      <div>
        <a href="https://www.mod.mil.gr/tmima-pliroforikis-epikoinonion-kai-neon-tehnologion-tpent/" target="_blank">
          <h3>
            ©2024 Powered by ITC Department MOD
          </h3>
        </a>     
      </div>

    </FooterStyled>
  );
}

const FooterStyled = styled.footer`
  width: 100%;
  margin-top: 10px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  text-align: center;
  a {
    color: white;
    transition: 0.5s ease-out;
  }
`;

// const StyledLink = styled.a`
//   color: #c8cdce;
//   transition: color 0.3s ease;
//   &:hover {
//     color: #4dbfd9; /* Change this color to your preferred color on hover */
//   }
// `;
