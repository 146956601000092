import { useState, useEffect } from "react";
import "./App.css";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Login from "./components/login/Login";
import Main from "./components/main/Main";

function App() {
  const [showapp, setShowapp] = useState(false);
  const [theme, setTheme] = useState("dark");
  const [expiryTimestamp, setExpiryTimestamp] = useState(null);

  useEffect(() => {
    if (showapp) {
      const minutes = 10;
      const expirationTime = new Date();
      expirationTime.setMinutes(expirationTime.getMinutes() + minutes); // Set expiration time to 10 minutes from now
      setExpiryTimestamp(expirationTime.getTime());

      const checkSession = () => {

        const difference = expirationTime.getTime() - Date.now();
        console.log(difference);
        const expired = difference <= 0;
        console.log(expired);

        if (expired) {
          clearInterval(interval);
          setShowapp(false);
          window.alert('Ο διαθέσιμος χρόνος σας έχει λήξει. Πατήστε ΟΚ για να μεταφερθείτε στην αρχική σελίδα.');
          window.location.href = '/';
        }
      };

      // Check the session cookie every second
      const interval = setInterval(checkSession, 1000);

      // Clear interval on component unmount or when showapp changes
      return () => clearInterval(interval);
    }
  }, [showapp]);

  const toggleTheme = () => {
    if (theme === "dark") {
      setTheme("light");
      document.body.style.background = "#e2e2e2";
      document.body.style.color = "black";
      document.querySelectorAll('a').forEach(a => {
        a.style.color = "black"; // Change color of all anchor elements to black
      });
    } else {
      setTheme("dark");
      document.body.style.background = "#2c3e50";
      document.body.style.color = "white";
      document.querySelectorAll('a').forEach(a => {
        a.style.color = "white"; // Change color of all anchor elements to white
      });
      document.querySelectorAll('main a').forEach(a => {
        a.style.color = "black";
      });
    }
  };

  return (
    <div className="App container">
      <Header theme={theme} toggleTheme={toggleTheme} expiryTimestamp={expiryTimestamp} />
      <Main />
      {!showapp && <Login setShowapp={setShowapp} />}
      <Footer theme={theme} />
    </div>
  );
}

export default App;